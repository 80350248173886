<template>
  <div>
    <div>
      <h1 class="align-c font42">{{ newTitle }}</h1>
      <div class="flex-c">
        <h5>新增图片：</h5>
        <el-upload
          :action="imgUrl"
          :headers="headersToken"
          :limit="1"
          accept="image/*"
          :before-upload="beforeUpload"
          :on-preview="onRreview"
          list-type="picture-card"
          :on-success="onSuccess"
          :on-remove="onRemove"
          :on-exceed="onExceed"
          name="imgFile"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <p class="ml100">上传图片 尺寸：471 * 287</p>
      </div>
      <div class="flex-c mt40">
        <h5>新闻标题-中文：</h5>
        <el-input v-model="editNew.titleCn"></el-input>
      </div>
      <h5 class="mt20 mb10">新闻内容-中文：</h5>
      <div>
        <div id="editor"></div>
      </div>
      <div class="flex-c mt40">
        <h5>新闻标题-英文：</h5>
        <el-input v-model="editNew.titleEn"></el-input>
      </div>
      <h5 class="mt20 mb10">新闻内容-英文：</h5>
      <div>
        <div id="editorEn"></div>
      </div>
    </div>
    <div class="mt30">
      <div v-if="newTitle !== '修改新闻'">
        <el-button type="primary" @click="addNewSubmit">提 交</el-button>
      </div>
      <div v-else>
        <el-button @click="goBackNew">返 回</el-button>
        <el-button
          type="primary"
          :loading="updateLoading"
          @click="updateNewSubmit"
          >修 改</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsById, addNews, updateNews } from "@/apis/news";
//
import { apiAll } from "@/utils/utils";
import E from "wangeditor";
export default {
  components: {},
  props: {},
  data() {
    return {
      newTitle: "新增新闻",
      // *新增新闻
      editNew: {
        titleCn: "",
        contentsEn: "",
        titleEn: "",
        contentsCn: "",
        image: "",
      },
      imgUrl: "", // *上传图片的地址
      urlApi: "", // *回显的地址
      headersToken: {}, // *上传图片的token
      dialogVisible: false, // *图片预览框是否显示
      dialogImageUrl: "", // *图片预览框的图片地址
      fileList: [], //* 图片回显
      editor: "", //* 富文本编辑器
      editorEn: "", //* 富文本编辑器
      updateNewID: "", //* 更新新闻的id
      updateLoading: false, //* 更新新闻的loading
    };
  },
  watch: {
    //* 监听路由变化 清空数据
    $route(nval) {
      if (!nval.query.id) {
        this.newTitle = "新增案例";
        this.editNew = {
          titleCn: "",
          contentsEn: "",
          titleEn: "",
          contentsCn: "",
          image: "",
        };
        this.editor.txt.html("");
        this.editorEn.txt.html("");
        this.fileList = [];
      }
    },
  },
  computed: {},
  mounted() {
    this.imgUrl = apiAll + "banner/upload "; // *上传图片的地址
    // *添加请求头
    this.headersToken = {
      token: localStorage.getItem("token"),
    };
    this.urlApi = apiAll + "images/"; // *回显的地址
    this.initWangeditorCn();
    this.initWangeditorEn();
    // *获取id
    this.updateNewID = this.$route.query.id;
    if (this.updateNewID) {
      this.newTitle = "修改新闻";
      this.getNewsById();
    }
  },
  methods: {
    // *图片限制长宽
    beforeUpload(file) {
      let _this = this;
      if (file.type.indexOf("image") < 0) {
        _this.$message({
          message: `请上传图片文件`,
          type: "warning",
        });
        return false;
      }
      //   const isLt2M = file.size / 1024 / 1024 < 2;
      const isSize = new Promise(function(resolve, reject) {
        let width = 471;
        let height = 287;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function() {
          let valid = img.width == width && img.height == height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          _this.$message({
            message: `上传图片尺寸只能是471*287!请重新选择!`,
            type: "warning",
            duration: 8000,
            showClose: true,
          });
          return Promise.reject();
        }
      );
      return isSize;
    },
    // *图片上传成功
    onSuccess(val) {
      this.editNew.image = `${this.urlApi}${val.data.file}`;
    },
    // *删除图片
    onRemove() {
      if (this.editNew.image) {
        this.editNew.image = "";
      }
    },
    // *图片是否可以预览
    onRreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // *图片上传超出个数
    onExceed() {
      this.$message({
        message: `当前只能上传一个文件`,
        type: "warning",
      });
    },
    // *富文本中文编辑器
    initWangeditorCn() {
      // 创建了一个wangEditor对象
      this.editor = new E("#editor");
      // 自定义菜单配置
      this.editor.config.menus = [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "backColor",
        "link",
        "list",
        "todo",
        "justify",
        "quote",
        // "emoticon",// 表情
        "image",
        "video",
        "table",
        "code",
        "splitLine",
        "undo",
        "redo",
      ];
      this.editor.config.uploadImgServer = this.imgUrl; // 图片上传的地址，这个是后端写好的

      // 创建
      this.editor.create();

      // 配置 onchange 回调函数
      this.editor.config.onchange = (newHtml) => {
        this.editNew.contentsCn = newHtml;
      };
      // 配置触发 onchange 的时间频率，默认为 200ms
      this.editor.config.onchangeTimeout = 200; // 修改为 500ms
      // 设置请求头
      this.editor.config.uploadImgHeaders = {
        token: localStorage.getItem("token"),
      };
      this.editor.config.uploadFileName = "imgFile"; // 后端接受上传文件的参数名
      this.editor.config.uploadImgHooks = {
        customInsert: (insertImg, result) => {
          // result为上传图片成功的时候返回的数据，这里我们需要后端返回的图片地址，输出一下就能拿到
          //   console.log(insertImg, result.data.file, editor);
          let url = result.data.file;
          insertImg(`${this.urlApi}${url}`); // 图片的函数
        },
      };
    },
    // *富文本英文编辑器
    initWangeditorEn() {
      // 创建了一个wangEditor对象
      this.editorEn = new E("#editorEn");
      // 自定义菜单配置
      this.editorEn.config.menus = [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "backColor",
        "link",
        "list",
        "todo",
        "justify",
        "quote",
        // "emoticon",// 表情
        "image",
        "video",
        "table",
        "code",
        "splitLine",
        "undo",
        "redo",
      ];
      this.editorEn.config.uploadImgServer = this.imgUrl; // 图片上传的地址，这个是后端写好的

      // 创建
      this.editorEn.create();

      // 配置 onchange 回调函数
      this.editorEn.config.onchange = (newHtml) => {
        this.editNew.contentsEn = newHtml;
      };
      // 配置触发 onchange 的时间频率，默认为 200ms
      this.editorEn.config.onchangeTimeout = 200; // 修改为 500ms
      // 设置请求头
      this.editor.config.uploadImgHeaders = {
        token: localStorage.getItem("token"),
      };
      this.editorEn.config.uploadFileName = "imgFile"; // 后端接受上传文件的参数名
      this.editorEn.config.uploadImgHooks = {
        customInsert: (insertImg, result) => {
          // result为上传图片成功的时候返回的数据，这里我们需要后端返回的图片地址，输出一下就能拿到
          //   console.log(insertImg, result.data.file, editor);
          let url = result.data.file;
          insertImg(`${this.urlApi}${url}`); // 图片的函数
        },
      };
    },

    // *通过id获取新闻详情
    getNewsById() {
      getNewsById({ id: this.updateNewID }).then((res) => {
        if (res.data.data.image) {
          this.fileList = [
            {
              url: res.data.data.image,
            },
          ];
        }
        this.editNew.image = res.data.data.image;
        this.editNew.titleCn = res.data.data.titleCn;
        this.editor.txt.html(res.data.data.contentsCn);
        this.editNew.titleEn = res.data.data.titleEn;
        this.editorEn.txt.html(res.data.data.contentsEn);
      });
    },
    // *新闻提交
    addNewSubmit() {
      // *提示
      if (!this.editNew.image) {
        this.$message({
          message: `请上传图片`,
          type: "warning",
        });
        return;
      } else if (!this.editNew.titleCn || !this.editNew.titleEn) {
        this.$message({
          message: `请填写 中文/英文 标题`,
          type: "warning",
        });
        return;
      } else if (!this.editNew.contentsCn || !this.editNew.contentsEn) {
        this.$message({
          message: `请填写 中文/英文 内容`,
          type: "warning",
        });
        return;
      }
      // *掉接口
      addNews(this.editNew).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            message: `添加成功`,
            type: "success",
          });
          // *清空
          this.editNew = {
            titleCn: "",
            contentsEn: "",
            titleEn: "",
            contentsCn: "",
          };
          this.editor.txt.html("");
          this.editorEn.txt.html("");
          this.fileList = [];
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    // *返回
    goBackNew() {
      this.$router.go(-1);
    },
    // *修改新闻
    updateNewSubmit() {
      this.updateLoading = true;
      updateNews({ id: this.updateNewID, ...this.editNew }).then((res) => {
        if (res.data.code == 0) {
          setTimeout(() => {
            this.updateLoading = false;
          }, 1000);
          this.$message({
            message: `修改成功`,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
  },
  destroyed() {
    this.editor = "";
    this.editorEn = "";
  },
};
</script>

<style lang="less" scoped>
h5 {
  font-size: 20px;
  width: 175px;
}
</style>
